@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
h1, h2, h3, h4, h5, h6, p {
    font-family: 'Open Sans', sans-serif;
}
h1 {
    font-weight: 700;
}
h2, h3, h4, h5, h6 {
    font-weight: 600;
}
p {
    font-weight: 400;
}
.container {
    position: relative;
    max-width: 1100px;
    margin: 40px auto 40px auto;
    overflow-y: auto;
}
.container strong {
    font-size: 20px;
    line-height: 26px;
}
.container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
}
.container a {
    text-decoration: none;
}
.white-bg {
    background: #fff;
}
.white-color {
    color: #fff!important;
}
.black-color {
    color: #000!important;
}
.primary-color {
    color: #e06b30!important;
}
.secondary-color {
    color: #2e2447!important;
}
.gr-border {
    border: solid #e4e4e4 3px;
}
.light-gr-bg {
    background: #eee;
}
a:link, a:visited, a:focus {
    color: #000;
    text-decoration: underline;
}
.hidden {
    display: none;
}
.divider {
    border-bottom: solid 1px #e4e4e4;
}
.date-width {
    width: 100% !important;
}
.MuiPickersToolbar-toolbar {
    background-color: var(--ion-color-primary) !important;
} 
.MuiPickersDay-daySelected {
    background-color: var(--ion-color-primary) !important;
}
.MuiButton-label {
    color: var(--ion-color-secondary) !important;
}
.MuiTextField-root {
    border-color: black;
}
.lined-text { 
    width:100%; 
    text-align:center; 
    border-bottom: 4px solid #e4e4e4; 
    line-height:0.1em; 
    margin:10px 0 20px; 
    color: #434343;
} 
.lined-text span { 
    background:#fff; 
    padding:0 10px; 
}
.underline {
    text-decoration: underline;
}
.cursor-pointer {
    cursor: pointer;
}
.active-tab {
    border-bottom: #2E2447 solid 4px;
}
.text-right {
    text-align: right;
}
.table-btn {
    position: absolute;
    right: 0;
}
.m-0 {
    margin: 0;
}
.m-1 {
    margin: 10px;
}
.m-2 {
    margin: 20px;
}
.m-3 {
    margin: 30px;
}
.m-4 {
    margin: 40px;
}
.m-5 {
    margin: 50px;
}
.ml-0 {
    margin: 0;
}
.ml-1 {
    margin-left: 10px;
}
.ml-2 {
    margin-left: 20px;
}
.ml-3 {
    margin-left: 30px;
}
.ml-4 {
    margin-left: 40px;
}
.ml-5 {
    margin-left: 50px;
}
.mt-0 {
    margin: 0;
}
.mt-1 {
    margin-top: 10px;
}
.mt-2 {
    margin-top: 20px;
}
.mt-3 {
    margin-top: 30px;
}
.mt-4 {
    margin-top: 40px;
}
.mt-5 {
    margin-top: 50px;
}
.mr-0 {
    margin: 0;
}
.mr-1 {
    margin-right: 10px;
}
.mr-2 {
    margin-right: 20px;
}
.mr-3 {
    margin-right: 30px;
}
.mr-4 {
    margin-right: 40px;
}
.mr-5 {
    margin-right: 50px;
}
.mb-0 {
    margin: 0;
}
.mb-1 {
    margin-bottom: 10px;
}
.mb-2 {
    margin-bottom: 20px;
}
.mb-3 {
    margin-bottom: 30px;
}
.mb-4 {
    margin-bottom: 40px;
}
.mb-5 {
    margin-bottom: 50px;
}
.p-0 {
    padding: 10px;
}
.p-1 {
    padding: 10px;
}
.p-2 {
    padding: 20px;
}
.p-3 {
    padding: 30px;
}
.p-4 {
    padding: 40px;
}
.p-5 {
    padding: 50px;
}
.pl-0 {
    padding: 10px;
}
.pl-1 {
    padding-left: 10px;
}
.pl-2 {
    padding-left: 20px;
}
.pl-3 {
    padding-left: 30px;
}
.pl-4 {
    padding-left: 40px;
}
.pl-5 {
    padding-left: 50px;
}
.pt-0 {
    padding: 10px;
}
.pt-1 {
    padding-top: 10px;
}
.pt-2 {
    padding-top: 20px;
}
.pt-3 {
    padding-top: 30px;
}
.pt-4 {
    padding-top: 40px;
}
.pt-5 {
    padding-top: 50px;
}
.pr-0 {
    padding: 10px;
}
.pr-1 {
    padding-right: 10px;
}
.pr-2 {
    padding-right: 20px;
}
.pr-3 {
    padding-right: 30px;
}
.pr-4 {
    padding-right: 40px;
}
.pr-5 {
    padding-right: 50px;
}
.pb-0 {
    padding: 10px;
}
.pb-1 {
    padding-bottom: 10px;
}
.pb-2 {
    padding-bottom: 20px;
}
.pb-3 {
    padding-bottom: 30px;
}
.pb-4 {
    padding-bottom: 40px;
}
.pb-5 {
    padding-bottom: 50px;
}
.float-left {
    float: left;
}

.react-autosuggest__container {
    position:relative;
    width: 100%;
}

.react-autosuggest__input {
    width: 100%; 
    height: 3rem;
    padding: 10px 20px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}


.react-autosuggest__suggestions-container--open {
    display:black;
    position:relative;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
} 

.react-autosuggest__suggestions-list {
    margin:0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__suggestion-match {
    color: var(--ion-color-primary);
    font-weight: bold;
}

ion-select {
    min-width: 100%;
    padding-left: 0;
}


/* Header Styling */
.header-shadow {
    box-shadow: 0px 10px 10px #CCC;
}
.header-row {
    max-width: 1100px;
    margin: auto auto;
    padding-left: 10px;
    padding-right: 10px;
}
.header-text-center {
    margin: 25px 0 25px 0;
}

.header-title-div {
    display: inline-block; 
    width: 80%;
    position: relative;
    bottom: 15%; 
    left: 2%
}

@media only screen and (max-width: 800px) {
    .header-text-center {
        margin: 0;
        padding-left: 10px;
    }
} 

/* Footer Styling */
.footer {
    background: var(--ion-color-secondary);
    color: #fff;
    margin: 0;
    border-top: #8e8c99 solid 8px;
    text-align: center;
    position: relative;
    bottom: 0;
}

.main-page{
    min-height: calc(100% - 122px);
}

/* not sure this is the best way to overide this css */
.popover-content.sc-ion-popover-md {
    width: max-content;
}

/* disabling radio button on popover picklists */
ion-select-popover ion-list ion-item ion-radio {
    --color: transparent;
    --color-checked: transparent;
  }


.modal-wrapper.sc-ion-modal-md {
    height: 180px;
    min-width: 330px;
    max-width: 1000px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    border-radius: 10px;
    position: fixed;
    top: 40%
}
.otp-container {
    margin: auto;
    max-width: 30ch;

}

.otp-input-center {
    text-align: center;
}

.otp-button-center  {
    margin: auto;
    width: fit-content;
    margin-top: 5px;
    flex-flow: column;
}